import $ from "jquery";
import "parsleyjs";
import hexSha1 from 'hex-sha1';
export class Forms {
    forms = null;
    constructor($selector = $('form')) {
        if ($selector.length > 0) {
            this.forms = $selector;
            this.addValidators();
        }
    }

    validate() {
        if (this.forms) {
            this.forms.parsley({
                inputs: Parsley.options.inputs + ',[data-parsley-recaptcha],[data-parsley-required-field],[data-parsley-check-children],[data-parsley-geocode]',
                excluded: Parsley.options.excluded + ', input:hidden',
                errorClass: 'is-invalid',
                errorsWrapper: '<div class="invalid-feedback line-height-sm"></div>',
                errorTemplate: '<span></span>'
            })
        }
    }

    addValidators() {
        Parsley.addValidator('requiredField', {
            requirementType: 'string',
            validate: function (_value, requirement, instance) {

                var $element = $(instance.$element);

                var input = $element.find('[name="' + requirement + '"]:checked, [name="' + requirement + '"]');
                var inputVal = '';
                var isValid = false;

                if (input.is(':radio')) {
                    isValid = input.filter(function () { return $(this).is(':checked'); }).length > 0;
                } else {
                    inputVal = input.val();
                    isValid = !!$.trim(inputVal);
                }

                console.log(requirement, 'isValid', isValid, 'Elements exists', input.length > 0);

                return isValid; // No section is filled, this validation fails
            }
        });
    }

    hexsha (form, hexField) {

    	$(form).on('submit', function(event){
    		// event.preventDefault();
    		let data = $(this).serializeArray();
    		let hexStr = '';
    		let amountField = $('[name="AMOUNT"]');

    		data = data.sort(function(a, b){
    			let nameA = a.name.toUpperCase(); // ignore upper and lowercase
    			  let nameB = b.name.toUpperCase(); // ignore upper and lowercase
    			  if (nameA < nameB) {
    			    return -1;
    			  }
    			  if (nameA > nameB) {
    			    return 1;
    			  }
    			  // names must be equal
    			  return 0;
    		}).filter(obj => {
    			return obj.name !== 'SHASIGN';
    		});
    		const phrase = 'W1ldW1ngs_g30rg30nn3';
    		let amount = 0;

    		for (var i = 0; i < data.length; i++) {
    			console.log(i, data[i]);
    			var name = data[i].name;
    			var value = data[i].value;
    			if (name === 'AMOUNT') {
    				value = value.replace(/\,/, '.').replace(/[^0-9\.]+/g, '') * 100;
    				amount = value;
    			}

    			hexStr += name + '=' + value + phrase;
    		}
    		console.log('form data', data, hexStr);
    		let hex = hexSha1(hexStr).toUpperCase();
    		$(hexField).val(hex);
    		amountField.val(amount);
    	});
    }
}