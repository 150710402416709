import { library, dom, config } from "@fortawesome/fontawesome-svg-core";
import {
	faEdit,
	faPlay,
	faPaperPlane,
	faMoneyBill,
	faLongArrowLeft,
	faLongArrowRight,
	faSortNumericUp,
	faSortNumericDown,
	faSortAlphaUp,
	faSortAlphaDown,
	faSortAmountUp,
	faSortAmountDown,
	faStars,
	faPlus,
	faMinus,
	faQuestionCircle,
	faAngleRight,
	faCheck,
	faCheckCircle,
	faTimesCircle,
	faGift,
	faLink,
	faChevronDown as falChevronDown,
	faQuoteLeft as falQuoteLeft,
	faQuoteRight as falQuoteRight,
	faCalendarDay as falCalendarDay,
	faLocationArrow as falLocationArrow,
	faDownload as falDownload,
} from "@fortawesome/pro-light-svg-icons";
import {
	faSearch as farSearch,
	faCircle as farCircle,
	faTag,
	faDollarSign,
	faClock as farClock,
	faEnvelope as farEnvelope,
	faCalendar as farCalendar,
	faLock as farLock,
} from "@fortawesome/pro-regular-svg-icons";
import {
	faChevronRight,
	faChevronLeft,
	faChevronDown,
	faCircle as fasCircle,
	faSearch as fasSearch,
	faChevronUp as fasChevronUp,
	faEdit as fasEdit,
	faPlay as fasPlay,
	faSpinner as fasSpinner,
	faPhone as fasPhone,
	faMobileAlt as fasMobileAlt,
	faMapMarkerAlt as fasMapMarkerAlt,
} from "@fortawesome/pro-solid-svg-icons";
import {
	faFacebookF,
	faXTwitter,
	faInstagram,
	faLinkedinIn,
	faYoutube,
	faPinterestP,
	faSkype,
	faWhatsapp
} from "@fortawesome/free-brands-svg-icons";

config.searchPseudoElements = true;
config.keepOriginalSource = false;

// light
library.add(
	falDownload,
	faEdit,
	faPaperPlane,
	faMoneyBill,
	faLongArrowLeft,
	faLongArrowRight,
	faSortNumericUp,
	faSortNumericDown,
	faSortAlphaUp,
	faSortAlphaDown,
	faSortAmountUp,
	faSortAmountDown,
	faStars,
	falLocationArrow,
	faPlus,
	faMinus,
	faQuestionCircle,
	faAngleRight,
	faCheck,
	faCheckCircle,
	faTimesCircle,
	faGift,
	falChevronDown,
	faLink,
	falQuoteLeft,
	falQuoteRight,
	falCalendarDay,
	faWhatsapp
);
// regular
library.add(
	farSearch,
	farCircle,
	faTag,
	faDollarSign,
	farClock,
	farEnvelope,
	farCalendar,
	farLock
);
// solid
library.add(
	faChevronRight,
	faChevronLeft,
	faChevronDown,
	fasCircle,
	fasSearch,
	fasChevronUp,
	fasEdit,
	fasPlay,
	fasSpinner,
	fasPhone,
	fasMobileAlt,
	fasMapMarkerAlt
);
// brands
library.add(
	faFacebookF,
	faXTwitter,
	faInstagram,
	faLinkedinIn,
	faYoutube,
	faPinterestP,
	faSkype
);

dom.i2svg();
dom.watch();
