import 'lazysizes/lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';
// import 'bootstrap-datepicker';
import "bootstrap";
import "./fontawesome";

import UIBindings from "./ui-bindings";
import { Forms } from "./form";
import { ExternalLinks } from "./externallinks";
import { PageTimer } from "./pagetimer";
import { ShareHandler } from "./share";

(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();
(new ShareHandler()).build();

const forms = new Forms();

forms.validate();
forms.hexsha('[data-hex-sha]', '[name="SHASIGN"]');

const UIBinding = new UIBindings();
UIBinding.formSubmitButton();
UIBinding.magnificPopup();
UIBinding.slickCarourel();
UIBinding.selectizeJs();
// UIBinding.datePicker();
UIBinding.bootstrap();
UIBinding.cookies();
UIBinding.pageVists();
UIBinding.captureUTMParameters();
UIBinding.videoEmbeds();