import $ from "jquery";
import "magnific-popup";
import "slick-carousel";
import "selectize";

import Cookies from "js-cookie";

export default class UIBindings {

    formSubmitButton() {
        $(document).on('submit', 'form', function (event) {

            let submitText = $(this).find('[data-submit-text]');
            let submittingText = $(this).find('[data-submitting-text]');
            let btn = submitText.closest('button');
            
            submittingText.removeClass('d-none');
            submitText.addClass('d-none');
            btn.prop('disabled', true);

        });
    }
    magnificPopup () {
        let image = $('[mfp-type="image"]');
        let gallery = $('[mfp-type="gallery"]');
        let inline = $('[mfp-type="inline"]');

        if (image.length > 0) {
            image.magnificPopup({type: "image"});
        }
        if (inline.length > 0) {
            inline.magnificPopup({
                type: 'inline',
                fixedContentPos: true,
                fixedBgPos: true,
                overflowY: 'auto',
                closeBtnInside: true,
                preloader: false,
                midClick: true,
                removalDelay: 300,
                mainClass: 'my-mfp-zoom-in'
            });
        }
        if (gallery.length > 0) {
            gallery.magnificPopup({
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: true,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    tCounter: '%curr% of %total%',
                    preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
                },
                zoom: {
                    enabled: true,
                    duration: 500 // don't foget to change the duration also in CSS
                }
            });
        }
    }

    slickCarourel() {
        let carousel = $('[data-slick]');
        let customPaging = function(slide, i){
            return $('<span><i class="far fa-circle fa-xs active slick-dot"/><i class="fas fa-circle fa-xs slick-dot"/></span>');
        }
        if (carousel.length > 0) {
            carousel.slick({customPaging: customPaging});
        }
    }


    selectizeJs(){
        $('[name="fields[country]"]').selectize({
            maxItems: 1,
            valueField: 'name',
            labelField: 'name',
            searchField: ['name','altSpellings'],
            preload: true,
            load: function(query, callback) {
                // if (!query.length) return callback();
                $.ajax({
                    url: '/countries',
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        country: query,
                    },
                    error: function() {
                        callback();
                    },
                    success: function(res) {
                        callback(res);
                    }
                });
              }
        });
    }

    datePicker() {
        $('[data-form-step], .tab-pane.active').datepicker({
             format: 'mm/dd/yyyy',
             inputs: $('[data-provide="datepicker"]'),
             autoclose: true,
             startDate: new Date(),
             templates: {
                leftArrow: '<i class="fal fa-long-arrow-left"></i>',
                rightArrow: '<i class="fal fa-long-arrow-right"></i>'
             }
        }); 
    }

    bootstrap() {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="click"]').on('click',  function(evt){
            evt.preventDefault();
            let target = $(this).data('targetLink');
            $(target).trigger('click');
        });
        
        document.addEventListener('mouseout', e => {
            // if no exit close popup cookie:
            const wildwingsCookie = localStorage.getItem('cookie_acceptance_wildwings');

            function acceptCookieNotice() {
                localStorage.setItem('cookie_acceptance_wildwings', true);
            }

            if (wildwingsCookie) {
                $('#exitIntent').modal('hide')
            }
            if (!wildwingsCookie && !e.toElement && !e.relatedTarget) {
                $('#exitIntent').modal('show')
                $('#exitIntent').on('hidden.bs.modal', function (e) {
                    acceptCookieNotice()
                })  
            }
        });
    }

    cookies() {
        let cookieCurrencyElements = $('[data-cookie-currency]');
        cookieCurrencyElements.click(function(evt) {
            if ($(this).hasClass('disabled')) {
                evt.preventDefault();
                return false;
            }
            var cookiekey = $(this).data('cookieCurrency');
            Cookies.set('currency', cookiekey, { expires: 365 });
            
            cookieCurrencyElements.filter(function(){
                return $(this).data('cookieCurrency') !== cookiekey;
            }).removeClass('disabled');

            cookieCurrencyElements.filter(function(){
                return $(this).data('cookieCurrency') === cookiekey;
            }).addClass('disabled');
       });
    }

    pageVists() {
        let pages = sessionStorage.getItem('pages');
        if (pages) {
            pages = JSON.parse(pages);
        } else {
            pages = [];
        }

        let lastPage = pages.length ? pages[pages.length - 1] : false;
        
        // check page reload/refresh
        if (!(lastPage && lastPage.url === page.url)) {
            pages.push(page);
            sessionStorage.setItem('pages', JSON.stringify(pages));
        }
        
        Cookies.set('pages', JSON.stringify(pages), { expires: 1 });
    }

    captureUTMParameters() {
        function getParameterByName(name, url = window.location.href) {
            name = name.replace(/[\[\]]/g, "\\$&");
            const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                  results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }
    
        const utmSource = getParameterByName('utm_source');
        const utmMedium = getParameterByName('utm_medium');
        const utmCampaign = getParameterByName('utm_campaign');
    
        if (utmSource) sessionStorage.setItem('utm_source', utmSource);
        if (utmMedium) sessionStorage.setItem('utm_medium', utmMedium);
        if (utmCampaign) sessionStorage.setItem('utm_campaign', utmCampaign);
    
        Cookies.set('utm_source', utmSource || '', { expires: 1 });
        Cookies.set('utm_medium', utmMedium || '', { expires: 1 });
        Cookies.set('utm_campaign', utmCampaign || '', { expires: 1 });
    }
    

    videoEmbeds() {
        let matrixVideos = document.querySelectorAll('.matrix-video');

        matrixVideos.forEach((video) => {
            video.querySelector('.embed-play-button').addEventListener('click', function() {
                video.querySelector('.embed-thumbnail').style.display = 'none';

                let videoIframe = document.createElement('iframe'),
                    embedVideo = video.querySelector('.embed-video'),
                    embeddedAssetUrl = embedVideo.getAttribute('data-url'),
                    videoId = embeddedAssetUrl.match(/v=([^&]+)/)[1],
                    embedUrl = 'https://www.youtube.com/embed/' + videoId + '?&autoplay=1';

                videoIframe.setAttribute('allowfullscreen', '');
                videoIframe.src = embedUrl;
                videoIframe.className = 'embed-responsive-item';
                videoIframe.allow = 'autoplay';
                embedVideo.appendChild(videoIframe);
                embedVideo.style.display = 'block';
                video.querySelector('.here-code').style.display = 'block';
            });
        });
    }
}